import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDriverGroups, useSessionAuth, useUnitGroups, useUserAuth, useWialonEvents } from "../common";
import { notifications } from "@mantine/notifications";
import { useInterval } from "@mantine/hooks";

type StoreContextType = ReturnType<typeof useStore>;

function useStore() {
	const [unitGroupsLoaded, setUnitGroupsLoaded] = useState(false);
	const [unitGroups, setUnitGroups] = useState<{ label: string; value: string }[]>([]);
	const [driverGroupsLoaded, setDriverGroupsLoaded] = useState(false);
	const [driverGroups, setDriverGroups] = useState<{ label: string; value: string }[]>([]);
	// const [isLoggedIn, setIsLoggedIn] = useState(false);

	const params = new URLSearchParams(document.location.search);
	const sid = params.get("sid");
	const user = params.get("user");
	const noCredentials = !sid || !user;

	const unitGroupsData = useUnitGroups();
	const driverGroupsData = useDriverGroups();

	const { data: eventsData, refetch } = useWialonEvents(sid);
	const { data: userRes, isLoading: userLoading } = useUserAuth(user);
	const { data: sessionRes, isLoading: sessionLoading } = useSessionAuth(sid);
	const userAuth = userRes?.data;
	const sessionAuth = sessionRes?.data;
	const Authenticating = userLoading || sessionLoading;

	const refetchInterval = useInterval(() => refetch(), 30000);

	useEffect(() => {
		refetchInterval.start();
		return refetchInterval.stop;
	}, []);

	useEffect(() => {
		if (!unitGroupsLoaded) {
			const { isError, data, error } = unitGroupsData;
			if (!isError && data && data.data) {
				const groups: { label: string; value: string }[] = data.data.map((unit: { id: number; name: string }) => {
					return {
						label: unit.name,
						value: `${unit.id}`,
					};
				});
				setUnitGroups(
					groups.findIndex((unitGroup) => unitGroup.value === `${process.env.REACT_APP_DEFAULT_UNIT_GROUP_ID}`) > -1
						? [
								groups[
									groups.findIndex((unitGroup) => unitGroup.value === `${process.env.REACT_APP_DEFAULT_UNIT_GROUP_ID}`)
								],
								...groups.filter((unitGroup) => unitGroup.value !== `${process.env.REACT_APP_DEFAULT_UNIT_GROUP_ID}`),
						  ]
						: groups
				);
				setUnitGroupsLoaded(true);
			} else if (isError) {
				console.log(error);
				notifications.show({
					id: "load-unit-groups-error",
					title: "Error",
					message: "Failed to load unit groups",
					color: "red",
					autoClose: false,
				});
				setUnitGroupsLoaded(true);
			}
		}
	}, [unitGroupsLoaded, unitGroupsData]);

	useEffect(() => {
		if (!driverGroupsLoaded) {
			const { isError, data, error } = driverGroupsData;
			if (!isError && data && data.data) {
				const groups: { label: string; value: string }[] = data.data.map((driver: { id: number; name: string }) => {
					return {
						label: driver.name,
						value: `${driver.id}`,
					};
				});
				setDriverGroups(
					groups.findIndex((driverGroup) => driverGroup.value === `${process.env.REACT_APP_DEFAULT_DRIVER_GROUP_ID}`) >
						-1
						? [
								groups[
									groups.findIndex(
										(driverGroup) => driverGroup.value === `${process.env.REACT_APP_DEFAULT_DRIVER_GROUP_ID}`
									)
								],
								...groups.filter(
									(driverGroup) => driverGroup.value !== `${process.env.REACT_APP_DEFAULT_DRIVER_GROUP_ID}`
								),
						  ]
						: groups
				);
				setDriverGroupsLoaded(true);
			} else if (isError) {
				console.log(error);
				notifications.show({
					id: "load-driver-groups-error",
					title: "Error",
					message: "Failed to load driver groups",
					color: "red",
					autoClose: false,
				});
				setDriverGroupsLoaded(true);
			}
		}
	}, [driverGroupsLoaded, driverGroupsData]);

	const value = useMemo(
		() => ({
			unitGroupsLoaded,
			unitGroups,
			driverGroupsLoaded,
			driverGroups,
			noCredentials,
			Authenticating,
			userAuth,
			sessionAuth,
		}),
		[
			unitGroupsLoaded,
			unitGroups,
			driverGroupsLoaded,
			driverGroups,
			noCredentials,
			Authenticating,
			userAuth,
			sessionAuth,
		]
	);

	return value;
}

export const StoreProvider = (props: React.PropsWithChildren) => {
	const value = useStore();
	return <StoreContext.Provider value={value} {...props} />;
};

export const StoreContext = createContext<StoreContextType | undefined>(undefined);
StoreContext.displayName = "StoreContext";

export const useStoreContext = () => {
	const context = useContext(StoreContext);
	if (context === undefined) {
		throw new Error(`useContext must be used within a Provider`);
	}
	return context;
};
