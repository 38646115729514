import { Schedule } from "../../types/types";
import { api } from "../api";

interface GetSchedulesRes {
	data: Schedule[];
}

export const deleteSchedule = (id: number): Promise<GetSchedulesRes> => {
	return api.delete(`schedule/${id}`);
};

// export const useDeleteSchedules = (id: number, options?: { then?: (x: GetSchedulesRes) => any }) => {
// 	const cacheKey: (string | Record<string, number | string | Date>)[] = [types.SCHEDULE, types.ALL];
// 	const query = useQuery(
// 		cacheKey,
// 		() =>
// 			deleteSchedules(id).then((res) => {
// 				options && options.then && options.then(res);
// 				return res;
// 			}),
// 		{ staleTime: 1000 * 5 }
// 	);
// 	return query;
// };
