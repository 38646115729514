import { DatePickerInput, DateTimePicker, MonthPickerInput, YearPickerInput } from "@mantine/dates";
import { PeriodPickerProps } from "../types";
import { useEffect, useState } from "react";
import { Indicator } from "@mantine/core";

export function PeriodPicker({ form, selectedReport }: PeriodPickerProps) {
	const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);

	useEffect(() => {
		if (selectedReport) {
			if (selectedReport.type === "none") {
				form.setFieldValue("from", new Date(new Date(new Date().getTime()).setHours(0, 0, 0, 0)));
				form.setFieldValue("to", new Date());
			}
		}
	}, [selectedReport]);

	if (selectedReport?.type === "none") {
		return <div></div>;
	}

	if (selectedReport?.type === "date") {
		return (
			<DatePickerInput
				mt="md"
				// defaultValue={new Date()}
				onChange={(date) => {
					if (date) {
						form.setFieldValue("from", new Date(new Date(date.getTime()).setHours(-17, 0, 0, 0)));
						form.setFieldValue(
							"to",
							new Date(new Date(date.getFullYear(), date.getMonth(), date.getDate()).setHours(7, 0, -1, 0))
						);
					}
				}}
				renderDay={(day) => {
					return day.toDateString() === new Date().toDateString() ? (
						<Indicator size={6} offset={-2}>
							{day.getDate()}
						</Indicator>
					) : (
						day.getDate()
					);
				}}
				label="Date input"
				placeholder="Date input"
				popoverProps={{ withinPortal: true }}
				maw={400}
				mx="auto"
			/>
		);
	}

	if (selectedReport?.type === "month") {
		// const defaultYearDate = new Date(new Date().getFullYear(), 0, 1);
		return (
			<MonthPickerInput
				mt="md"
				// value={defaultYearDate}
				onChange={(date) => {
					if (date) {
						console.log(date);
						form.setFieldValue("from", new Date(new Date(date.getTime()).setHours(0, 0, 0, 0)));
						form.setFieldValue(
							"to",
							new Date(new Date(date.getFullYear(), date.getMonth() + 1, 1).setHours(0, 0, -1, 0))
						);
					}
				}}
				label="Date input"
				placeholder="Date input"
				popoverProps={{ withinPortal: true }}
				maw={400}
				mx="auto"
			/>
		);
	}

	if (selectedReport?.type === "year") {
		return (
			<YearPickerInput
				mt="md"
				// defaultValue={new Date()}
				onChange={(date) => {
					if (date) {
						form.setFieldValue("from", new Date(new Date(date.getTime()).setHours(0, 0, 0, 0)));
						form.setFieldValue(
							"to",
							new Date(new Date(date.getFullYear() + 1, date.getMonth(), 0).setHours(23, 59, 59, 0))
						);
					}
				}}
				label="Date input"
				placeholder="Date input"
				popoverProps={{ withinPortal: true }}
				maw={400}
				mx="auto"
			/>
		);
	}

	//TODO: change the exclude behavior
	if (selectedReport?.type === "range") {
		return (
			<DatePickerInput
				mt="md"
				type="range"
				value={value}
				allowSingleDateInRange
				onChange={(date) => {
					if (date[0]) {
						form.setFieldValue("from", new Date(new Date(date[0].getTime()).setHours(0, 0, 0, 0)));
					}
					if (date[1]) {
						form.setFieldValue("to", new Date(new Date(date[1].getTime()).setHours(23, 59, 59, 0)));
					}
					setValue(date);
				}}
				excludeDate={(date) => {
					let excluded = false;
					const firstDate = value[0];
					if (firstDate && selectedReport.limit === "12 months") {
						const lowerLimit = new Date(new Date(firstDate).setFullYear(firstDate.getFullYear() - 1));
						const upperLimit = new Date(new Date(firstDate).setFullYear(firstDate.getFullYear() + 1));
						excluded = lowerLimit > date || date > upperLimit;
					} else if (firstDate && selectedReport.limit === "31 days") {
						const lowerLimit = new Date(new Date(firstDate).setDate(firstDate.getDate() - 30));
						const upperLimit = new Date(new Date(firstDate).setDate(firstDate.getDate() + 30));
						excluded = lowerLimit > date || date > upperLimit;
					} else if (firstDate && selectedReport.limit === "3 months") {
						const lowerLimit = new Date(new Date(firstDate).setMonth(firstDate.getMonth() - 3));
						const upperLimit = new Date(new Date(firstDate).setMonth(firstDate.getMonth() + 3));
						excluded = lowerLimit > date || date > upperLimit;
					}
					return excluded;
				}}
				renderDay={(day) => {
					return day.toDateString() === new Date().toDateString() ? (
						<Indicator size={6} offset={-2}>
							{day.getDate()}
						</Indicator>
					) : (
						day.getDate()
					);
				}}
				label="Date input"
				placeholder="Date input"
				popoverProps={{ withinPortal: true }}
				maw={400}
				mx="auto"
			/>
		);
	}

	return (
		<>
			<DateTimePicker
				mt="md"
				label="Pick date and time"
				placeholder="Pick date and time"
				maw={400}
				mx="auto"
				popoverProps={{ withinPortal: true }}
				{...form.getInputProps("from")}
				renderDay={(day) => {
					return day.toDateString() === new Date().toDateString() ? (
						<Indicator size={6} offset={-2}>
							{day.getDate()}
						</Indicator>
					) : (
						day.getDate()
					);
				}}
			/>
			<DateTimePicker
				mt="md"
				label="Pick date and time"
				placeholder="Pick date and time"
				maw={400}
				mx="auto"
				popoverProps={{ withinPortal: true }}
				{...form.getInputProps("to")}
				renderDay={(day) => {
					return day.toDateString() === new Date().toDateString() ? (
						<Indicator size={6} offset={-2}>
							{day.getDate()}
						</Indicator>
					) : (
						day.getDate()
					);
				}}
			/>
		</>
	);
}
