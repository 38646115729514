import {
	Card,
	Loader,
	Overlay,
	Stack,
	Title,
	Text,
	Select,
	Group,
	useMantineTheme,
	Button,
	Switch,
	TextInput,
} from "@mantine/core";
import { yearlyReports } from "../common";
import { YearPickerInput } from "@mantine/dates";
import { Dropzone, MS_EXCEL_MIME_TYPE } from "@mantine/dropzone";
import { TbUpload, TbX } from "react-icons/tb";
import { useEffect, useMemo, useRef, useState } from "react";
import { groupBy, guessMonth, unixFromToFromYear } from "../utils";
import { SortableContainer } from "./Sortable";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { excelColumnExtractor, generateAndDownloadExcel, getColNames } from "../common/excel";
import { FileType, YearlyReport } from "../types";
import { notifications } from "@mantine/notifications";

export function YearlyCard() {
	const [selectedReport, setSelectedReport] = useState<YearlyReport | undefined>(undefined);
	const [year, setYear] = useState(new Date(new Date().getFullYear() - 1, 0, 1));
	const [files, setFiles] = useState<FileType[]>([]);
	const [addFiles, setAddfiles] = useState(false);
	const [isloading, setIsloading] = useState(false);
	const openRef = useRef<() => void>(null);
	const theme = useMantineTheme();

	const memoedContainer = useMemo(
		() => (files && files?.length ? <SortableContainer files={files} setFiles={setFiles} /> : undefined),
		[files]
	);

	return (
		<Card withBorder shadow="sm" radius="md" p="lg">
			{isloading && (
				<Overlay blur={5} center>
					<Stack align="center">
						<Loader />
						<Text align="center" px="md">
							Generating Report
						</Text>
					</Stack>
				</Overlay>
			)}
			<Title order={5}>Yearly Reports</Title>
			<Select
				mt="md"
				label="Select report template"
				// {...form.getInputProps("reportTemplate")}
				onChange={(v) => {
					if (v) {
						setSelectedReport(yearlyReports.find((report) => report.value === v));
						// form.setFieldValue("reportTemplate", v);
					}
				}}
				data={yearlyReports.map((report) => ({
					value: report.value,
					label: report.label,
				}))}
				withinPortal
				searchable
			/>
			<YearPickerInput
				mt="md"
				label="Pick date"
				placeholder="Pick date"
				valueFormat="YYYY"
				popoverProps={{ withinPortal: true }}
				value={year}
				onChange={(v) => {
					if (v) setYear(v);
				}}
			/>
			{/* <TextInput mt="md" label="Sheet name" placeholder="Sheet name" /> */}
			<Stack align="center" mt="md" spacing="md">
				<Button
					onClick={() => {
						if (openRef.current) openRef.current();
					}}
				>
					Select files
				</Button>
				<Switch
					label="add files to current files"
					checked={addFiles}
					onChange={(event) => setAddfiles(event.currentTarget.checked)}
				/>
			</Stack>
			<Dropzone
				mt="md"
				openRef={openRef}
				activateOnClick={false}
				styles={{
					inner: {
						pointerEvents: "all",
						cursor: "initial",
					},
					root: {
						"&[data-accept]": {
							color: theme.white,
							backgroundColor: `${theme.colors.blue[6]}54`,
						},

						"&[data-reject]": {
							color: theme.white,
							backgroundColor: `${theme.colors.red[6]}54`,
						},
					},
				}}
				onDrop={async (newFiles) => {
					const tempFiles = await Promise.all(
						newFiles.map(async (file, i) => ({
							id: i,
							name: file.name,
							arrayBuffer: await file.arrayBuffer(),
							size: file.size,
							error: false,
						}))
					);
					if (addFiles && files) {
						setFiles([...files, ...tempFiles]);
					} else {
						setFiles(tempFiles);
					}
					newFiles.forEach((file) => {
						console.log(guessMonth(file.name, []));
					});
				}}
				onReject={(files) => console.log("rejected files", files)}
				maxSize={10 * 1024 ** 2}
				maxFiles={12}
				accept={MS_EXCEL_MIME_TYPE}
			>
				<Group position="center" spacing="xl">
					<DndProvider backend={HTML5Backend}>
						<Dropzone.Accept>
							<TbUpload size="3.2rem" color={theme.colors.blue[theme.colorScheme === "dark" ? 4 : 6]} />
						</Dropzone.Accept>
						<Dropzone.Reject>
							<TbX size="3.2rem" color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]} />
						</Dropzone.Reject>

						{(!files || files?.length === 0) && (
							<div>
								<Text size="xl" inline>
									Drag files here or click to select
								</Text>
								<Text size="sm" color="dimmed" inline mt={7}>
									Attach as many as 12 files, each file should not exceed 10mb
								</Text>
							</div>
						)}
						{memoedContainer}
					</DndProvider>
				</Group>
			</Dropzone>
			<Group align="right" position="right" mt="md">
				<Button
					onClick={() => {
						if (selectedReport) {
							setIsloading(true);
							setFiles((prev) => {
								return prev.map((file) => {
									return {
										...file,
										error: !selectedReport.mustCols.every((col) =>
											getColNames(file.arrayBuffer, selectedReport.sheet).includes(col)
										),
									};
								});
							});
							const res = files
								? files.map((file, i) => {
										//TODO: map the order of the files
										return excelColumnExtractor(
											file.arrayBuffer,
											selectedReport.cols,
											selectedReport.groupingCols,
											`${i + 1}`,
											selectedReport.sheet
										);
								  })
								: null;
							if (res) {
								console.log(
									res,
									groupBy(res.flat(), (i) => i[selectedReport.cols[0]]),
									selectedReport.formatter(groupBy(res.flat(), (i) => i[selectedReport.cols[0]]))
								);
								generateAndDownloadExcel(
									selectedReport.formatter(groupBy(res.flat(), (i) => i[selectedReport.cols[0]])),
									{ ...unixFromToFromYear(year.getFullYear()), reportTemplate: selectedReport.label },
									`${selectedReport.label}-${year.getFullYear()}-yearly-report`,
									`${year.getFullYear()}`,
									[selectedReport.reportColsOrder],
									undefined,
									() => {
										setIsloading(false);
										notifications.show({
											title: "Success",
											message: "Your report has been downloaded!",
											color: "green",
										});
									}
								);
							}
						}
					}}
					disabled={!selectedReport || !files}
				>
					Generate
				</Button>
				{/* <Button disabled={!selectedReport || !files} onClick={() => console.log(files ? files[0].arrayBuffer : "WTF?")}>
					Generate
				</Button> */}
			</Group>
		</Card>
	);
}
