import { useQuery } from "react-query";
import { api } from "../api";
import { types } from "../../constants";

interface GetUserAuthRes {
	data: number;
}

export const getUserAuth = (user: string | null): Promise<GetUserAuthRes> => {
	return api.get(`auth/user/${user}`);
};

export const useUserAuth = (user: string | null, options?: { then?: (x: GetUserAuthRes) => any }) => {
	const cacheKey: (string | Record<string, number | string | Date>)[] = [types.AUTH_USER];
	if (user) cacheKey.push(user);
	const query = useQuery(
		cacheKey,
		() =>
			getUserAuth(user).then((res) => {
				options && options.then && options.then(res);
				return res;
			}),
		{ enabled: user !== null, staleTime: 1000 * 5 }
	);
	return query;
};
