import { Button, Card, Loader, NumberInput, Overlay, Select, Stack, Text, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { months, unixTimeStamp } from "../utils";
import { ReportData, ReportForm } from "../types";
import { api, reportOrders } from "../common/";
import { useEffect, useState } from "react";
import { PeriodPicker } from "./PeriodPicker";
import { notifications } from "@mantine/notifications";
import { useStoreContext } from "../context";
import { generateAndDownloadExcel } from "../common/excel";

export function ReportCard({ title, reportsData }: { title: string; reportsData: ReportData[] }) {
	const [selectedReport, setSelectedReport] = useState<ReportData | undefined>(undefined);
	const [isloading, setIsloading] = useState(false);

	const { unitGroups, driverGroups, unitGroupsLoaded, driverGroupsLoaded } = useStoreContext();

	//TODO: test the time for time zones

	const form = useForm<ReportForm>({
		initialValues: {
			reportTemplate: "",
			targetGroup: "0",
			from: null,
			to: null,
			petrolCost: 0,
			dieselCost: 0,
		},
	});

	useEffect(() => {
		if (unitGroupsLoaded && driverGroupsLoaded) {
			form.setFieldValue(
				"targetGroup",
				`${
					selectedReport?.drivers
						? process.env.REACT_APP_DEFAULT_DRIVER_GROUP_ID
						: process.env.REACT_APP_DEFAULT_UNIT_GROUP_ID
				}`
			);
		}
	}, [unitGroupsLoaded, driverGroupsLoaded, selectedReport?.drivers]);

	function handleSubmit(values: ReportForm) {
		if (!values.from || !values.to) {
			notifications.show({
				title: "Error",
				message: "You must select a date",
				color: "red",
				autoClose: false,
			});
			return;
		}
		if (values.targetGroup === "0") {
			notifications.show({
				title: "Error",
				message: "You must select a unit group",
				color: "red",
				autoClose: false,
			});
		}
		const formattedFromDate = `${values.from?.getDate()}-${
			values.from?.getMonth() !== undefined ? values.from?.getMonth() + 1 : ""
		}-${values.from?.getFullYear()}`;
		const formattedToDate = `${values.to?.getDate()}-${
			values.to?.getMonth() !== undefined ? values.to?.getMonth() + 1 : ""
		}-${values.to?.getFullYear()}`;
		const formattedDate =
			formattedFromDate === formattedToDate ? formattedFromDate : `${formattedFromDate} to ${formattedToDate}`;
		if (!values.petrolCost || !selectedReport?.cost) delete values?.petrolCost;
		if (!values.dieselCost || !selectedReport?.cost) delete values?.dieselCost;
		if (values.reportTemplate === "dailymileage8to5") {
			values.from = new Date(values.to.getFullYear(), values.to.getMonth(), values.to.getDate() - 1, 20, 0, 0);
			values.to = new Date(values.to.getFullYear(), values.to.getMonth(), values.to.getDate(), 5, 0, 0);
		}
		const newValues = {
			...values,
			from: unixTimeStamp(values.from),
			to: unixTimeStamp(values.to),
		};
		const params = new URLSearchParams();
		params.append("from", `${newValues.from}`);
		params.append("to", `${newValues.to}`);
		params.append("object", `${newValues.targetGroup}`);
		if (newValues.petrolCost) params.append("petrolcost", `${newValues.petrolCost}`);
		if (newValues.dieselCost) params.append("dieselcost", `${newValues.dieselCost}`);
		console.log(newValues);
		setIsloading(true);
		const now = new Date();
		const isStartofTheMonth = values.from?.getDate() === 1;
		const isEndofTheSameMonth =
			values.from?.getMonth() === values.to?.getMonth() &&
			values.from?.getFullYear() === values.to?.getFullYear() &&
			new Date(
				values.to?.getFullYear() || now.getFullYear(),
				(values.to?.getMonth() || now.getMonth()) + 1,
				0
			).getDate() === values.to?.getDate();
		const period =
			isStartofTheMonth && isEndofTheSameMonth
				? `${months[values.to?.getMonth()]} ${values.to?.getFullYear()}`
				: formattedDate;
		console.log(period);
		api
			.get<Record<string, any[]>[]>(`report/${newValues.reportTemplate}?${params}`)
			.then((res) => {
				generateAndDownloadExcel(
					res?.data,
					newValues,
					`${selectedReport?.label || newValues.reportTemplate} ${formattedDate}`,
					period,
					reportOrders[values.reportTemplate],
					selectedReport,
					() => {
						setIsloading(false);
						notifications.show({
							title: "Success",
							message: "Your report has been downloaded!",
							color: "green",
						});
					}
				);
			})
			.catch((err) => {
				notifications.show({
					title: "Error",
					message: err.response?.data?.message || "Something went wrong!!",
					color: "red",
					autoClose: false,
				});
				console.log(err, err.response?.data?.message);
				setIsloading(false);
			});
	}

	return (
		<Card w={400} shadow="sm" padding="lg" radius="md" withBorder>
			{(!unitGroupsLoaded || isloading) && (
				<Overlay blur={5} center>
					<Stack align="center">
						<Loader />
						<Text align="center" px="md">
							{isloading ? "Fetching vehicles GPS data then generating the report" : "Loading..."}
						</Text>
					</Stack>
				</Overlay>
			)}
			<Title order={5}>{title}</Title>
			<form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
				<Select
					mt="md"
					label="Select report template"
					{...form.getInputProps("reportTemplate")}
					onChange={(v) => {
						if (v) {
							setSelectedReport(reportsData.find((report) => report.value === v));
							form.setFieldValue("reportTemplate", v);
						}
					}}
					data={reportsData.map((report) => ({
						value: report.value,
						label: report.label,
						disabled: report.disabled,
						group: report.group,
					}))}
					withinPortal
					searchable
				/>
				<Select
					mt="md"
					label="Select target group"
					{...form.getInputProps("targetGroup")}
					data={selectedReport?.drivers ? driverGroups : unitGroups}
					withinPortal
					searchable
				/>
				<PeriodPicker form={form} selectedReport={selectedReport} />
				{selectedReport?.cost && (
					<>
						<NumberInput mt="md" label="Petrol Cost" {...form.getInputProps("petrolCost")} precision={2} step={0.05} />
						<NumberInput mt="md" label="Diesel Cost" {...form.getInputProps("dieselCost")} precision={2} step={0.05} />
					</>
				)}
				<Button mt="md" type="submit" fullWidth>
					Submit
				</Button>
			</form>
		</Card>
	);
}
