import update from "immutability-helper";
import { useCallback } from "react";
import { SortableCard } from "./SortableCard";
import { ActionIcon, Grid, Text } from "@mantine/core";
import { TbX } from "react-icons/tb";
import { months } from "../../utils";

const style = {
	width: "100%",
	marginLeft: "auto",
	marginRight: "auto",
};

export interface Item {
	id: number;
	text: string;
}

// export interface ContainerState {
// 	cards: Item[];
// }

export function SortableContainer<T extends { id: number; name: string; error?: boolean }>({
	// cards,
	files,
	setFiles,
}: {
	// cards: { id: number; text: string }[];
	files: T[];
	setFiles: React.Dispatch<React.SetStateAction<T[]>>;
}) {
	const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
		setFiles((prevCards: T[]) =>
			update(prevCards, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevCards[dragIndex]],
				],
			})
		);
	}, []);

	const renderCard = useCallback(
		(card: T, index: number) => {
			return (
				<Grid key={`c${index}`}>
					<Grid.Col span={3}>
						<Text>
							month {index + 1} ({months[index]}):
						</Text>
					</Grid.Col>
					<Grid.Col span={8}>
						<SortableCard
							key={card.id}
							index={index}
							id={card.id}
							text={card.name}
							error={card?.error === undefined ? false : card.error}
							moveCard={moveCard}
						/>
					</Grid.Col>
					<Grid.Col span={1}>
						<ActionIcon
							mx="auto"
							color="red"
							c="red"
							onClick={() => {
								setFiles(files.slice(0, index).concat(files.slice(index + 1)));
								if (files) setFiles(files.slice(0, index).concat(files.slice(index + 1)));
							}}
						>
							<TbX />
						</ActionIcon>
					</Grid.Col>
				</Grid>
			);
		},
		[files]
	);

	return (
		<>
			<div style={style}>{files.map((card, i) => renderCard(card, i))}</div>
		</>
	);
}
