import { MantineProvider, ColorScheme, ColorSchemeProvider } from "@mantine/core";

import { Notifications } from "@mantine/notifications";
import { useColorScheme, useLocalStorage, useHotkeys } from "@mantine/hooks";
import { StoreProvider } from "./context";
import { QueryClient, QueryClientProvider } from "react-query";
import { Home } from "./pages";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			// onError: (err) => {
			// 	if ((err as IaxiosError).response?.data?.message === "Unauthorized") {
			// 		if (!publicRoutes.includes(window.location.pathname))
			// 			window.location.href = `/login?redirect=${window.location.pathname}`;
			// 	}
			// },
			retry: false,
			refetchOnWindowFocus: false,
			refetchOnMount: true,
			// staleTime: 1000 * 60 * 5,
		},
	},
});

function App() {
	const preferredColorScheme = useColorScheme(undefined, {
		getInitialValueInEffect: true,
	});
	const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
		key: "ats-amana-reports-color-scheme",
		defaultValue: preferredColorScheme,
		// getInitialValueInEffect: true,
	});
	const toggleColorScheme = (value?: ColorScheme) =>
		setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
	useHotkeys([["mod+J", () => toggleColorScheme()]]);

	return (
		<ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
			<MantineProvider
				withGlobalStyles
				withNormalizeCSS
				theme={{
					primaryColor: "orange",
					primaryShade: 8,
					loader: "bars",
					colorScheme,
				}}
			>
				<QueryClientProvider client={queryClient}>
					<StoreProvider>
						<Notifications position="top-right" />
						<Home />
					</StoreProvider>
				</QueryClientProvider>
			</MantineProvider>
		</ColorSchemeProvider>
	);
}

export default App;
