import {
	AppShell,
	Navbar,
	NavLink,
	Header,
	Group,
	ActionIcon,
	Grid,
	Container,
	Card,
	Modal,
	Image,
	useMantineColorScheme,
	LoadingOverlay,
	Center,
} from "@mantine/core";
import { TbSun, TbMoonStars, TbClockCog } from "react-icons/tb";
import { ErrorPage, ReportCard, ScheduleForm, YearlyCard } from "../components";
import logo from "../assets/ats_logo_xs.png";
import amanalogo from "../assets/amana_logo.png";
import { useMediaQuery, useDisclosure } from "@mantine/hooks";
import { useStoreContext } from "../context";
import { Interval, reportsData } from "../common";

export function Home() {
	const matches = useMediaQuery("(min-width: 56.25em)");
	const [opened, { open, close }] = useDisclosure(false);
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const { noCredentials, Authenticating, userAuth, sessionAuth } = useStoreContext();

	return (
		<AppShell
			padding="md"
			navbar={
				!matches ? undefined : (
					<Navbar width={{ base: 300 }}>
						<Navbar.Section>
							<Image maw={240} mx="auto" my="md" radius="md" src={logo} alt="ATS Logo" />
							<Image maw={240} mx="auto" my="md" radius="md" src={amanalogo} alt="AMANA Logo" />
						</Navbar.Section>
						<Navbar.Section grow mt={50}>
							<NavLink
								label={`${colorScheme === "dark" ? "light" : "dark"} theme`}
								icon={colorScheme === "dark" ? <TbSun size="1.1rem" /> : <TbMoonStars size="1.1rem" />}
								onClick={() => toggleColorScheme()}
							/>
							{noCredentials || Authenticating || !userAuth || !sessionAuth ? undefined : (
								<NavLink label={"schedule"} icon={<TbClockCog size="1.1rem" />} onClick={() => open()} />
							)}
						</Navbar.Section>
					</Navbar>
				)
			}
			header={
				matches ? undefined : (
					<Header height={60} p="xs">
						<Group h={"100%"} align="center" position="right">
							{noCredentials || Authenticating || !userAuth || !sessionAuth ? undefined : (
								<ActionIcon
									variant="outline"
									// color={colorScheme === "dark" ? "yellow" : "blue"}
									onClick={() => open()}
									title="schedule"
								>
									<TbClockCog size="1.1rem" />
								</ActionIcon>
							)}
							<ActionIcon
								variant="outline"
								color={colorScheme === "dark" ? "yellow" : "blue"}
								onClick={() => toggleColorScheme()}
								title="Toggle color scheme"
							>
								{colorScheme === "dark" ? <TbSun size="1.1rem" /> : <TbMoonStars size="1.1rem" />}
							</ActionIcon>
						</Group>
					</Header>
				)
			}
			styles={(theme) => ({
				main: { backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0] },
			})}
		>
			{noCredentials ? (
				<ErrorPage type="credentials" />
			) : (
				<>
					<LoadingOverlay visible={Authenticating} />
					{Authenticating ? undefined : !userAuth ? (
						<ErrorPage type="user" />
					) : !sessionAuth ? (
						<ErrorPage type="session" />
					) : (
						<>
							<Grid m={0} p={0}>
								<Grid.Col md={0} sm={0} hidden={matches}>
									<Image maw={240} mx="auto" my="md" radius="md" src={logo} alt="ATS Logo" />
									<Image maw={240} mx="auto" my="md" radius="md" src={amanalogo} alt="AMANA Logo" />
								</Grid.Col>
								<Grid.Col md={12} sm={12}>
									<Container my="lg">
										<Card shadow="sm" padding="lg" radius="md" withBorder>
											<Center>
												{/* TODO: Change to Grid */}
												{/* <SimpleGrid cols={2} breakpoints={[{ maxWidth: "sm", cols: 1 }]} spacing={40}> */}
												<ReportCard
													title="Daily & Monthly Reports"
													reportsData={reportsData.filter(
														(report) => report.group === Interval.DAILY || report.group === Interval.MONTHLY
													)}
												/>
												{/* <ReportCard
														title="Yearly Partly Reports"
														reportsData={reportsData.filter((report) => report.yearly)}
													/> */}
												{/* </SimpleGrid> */}
											</Center>
										</Card>
									</Container>
								</Grid.Col>
								<Grid.Col md={12} sm={12}>
									<Container my="lg">
										<Card shadow="sm" padding="lg" radius="md" withBorder>
											<YearlyCard />
										</Card>
									</Container>
								</Grid.Col>
							</Grid>
							<Modal size={"70%"} opened={opened} onClose={close} title="Schedule">
								<ScheduleForm />
							</Modal>
						</>
					)}
				</>
			)}
		</AppShell>
	);
}
