import { FileMonth } from "./types";

export function unixTimeStamp(date: Date) {
	return Math.floor(date.getTime() / 1000);
}

export function maxColWidth(
	data: Record<string, string | number>[],
	colsOrder: (string | number)[] = Object.keys(data[0] || [])
) {
	const colWisths: Record<string, number> = {};
	const temp = [...colsOrder];
	data.forEach((row, index) => {
		Object.entries(row).forEach(([key, value]) => {
			if (typeof value === "number") {
				if (!colWisths[key]) {
					colWisths[key] = `${value}`.length - 1;
				} else {
					if (colWisths[key] < `${value}`.length - 1) {
						colWisths[key] = `${value}`.length - 1;
					}
				}
			} else if (value) {
				if (!colWisths[key]) {
					colWisths[key] = value.length;
				} else {
					if (colWisths[key] < value.length) {
						colWisths[key] = value.length;
					}
				}
			} else {
				if (!colWisths[key]) {
					colWisths[key] = 5;
				}
			}
			if (key.length > colWisths[key]) {
				colWisths[key] = key.length;
			}
		});
	});
	Object.entries(colWisths).forEach(([key, value]) => {
		temp[colsOrder.indexOf(key)] = value;
	});
	return temp.map((c) => {
		return { wch: (c as number) + 1 };
	});
}

export function dataExplorer(data: Record<string, string | number>[], colsOrder?: (string | number)[]) {
	const colsKeys = colsOrder
		? [...colsOrder, ...Object.keys(data[0] || []).filter((k) => !colsOrder.includes(k))]
		: [...Object.keys(data[0] || [])];
	const colsLength = colsKeys.length;
	// const colsTypes = colsKeys.map((col) => {
	// 	return typeof data[0][col];
	// });
	const rowsLength = data.length;
	return {
		colsKeys,
		colsLength,
		// colsTypes,
		rowsLength,
		data: josnToSortedAoa(data, colsOrder),
	};
}

export function josnToSortedAoa(
	data: Record<string, string | number>[],
	colsOrder: (string | number)[] = Object.keys(data[0] || [])
) {
	return data.map((row) => {
		let temp: typeof colsOrder = [...colsOrder];
		const found: typeof colsOrder = [];
		temp.forEach((c, i) => {
			if (row[`${c}`] === undefined) {
				temp[i] = "";
				found.push(c);
			} else {
				temp[i] = row[`${c}`];
				found.push(c);
			}
		});
		temp = [
			...temp,
			...Object.entries(row)
				.filter(([key, value]) => !found.includes(key))
				.map(([_, value]) => value),
		];
		// Object.entries(row).forEach(([key, value]) => {
		// 	temp[colsOrder.indexOf(key)] = value;
		// });
		return temp;
	});
}

export const months = Array(12)
	.fill(0)
	.map((_, i) => new Date(new Date().getFullYear(), i, 1).toLocaleString("en-us", { month: "long" }));

export const days = Array(31)
	.fill("")
	.map((_, i) => `${i + 1}`);

export function enumToSelectData(enumData: Record<string, string>) {
	return Object.values(enumData).map((value) => {
		return {
			label: value.charAt(0).toUpperCase() + value.slice(1),
			value: value,
		};
	});
}

/**
 * Groups the elements of an array by the specified key.
 *
 * @param {T[]} arr - The array to be grouped
 * @param {(i: T) => K} key - The function to extract the key for grouping
 * @return {Record<K, T[]>} An object with keys as the grouped values and values as arrays of grouped elements
 */
export function groupBy<T, K extends keyof any>(arr: T[], key: (i: T) => K) {
	return arr.reduce((groups, item) => {
		(groups[key(item)] ||= []).push(item);
		return groups;
	}, {} as Record<K, T[]>);
}

/**
 * Calculates the total value for each specified key from the grouped data, and applies any additional processing to the grouped values.
 *
 * @param {Record<K, T[]>} grouped - The grouped data to calculate totals from.
 * @param {string} groupedKey - The key used for grouping the data.
 * @param {string[]} totalKeys - The keys for which the total value will be calculated.
 * @param {{ key: string; process: (currVal: T[]) => any }[]} [extras] - Optional extra processing to apply to the grouped values. Array of objects with 'key' {string} and 'process' {function} properties.
 * @return {Object[]} An array of objects containing the calculated totals and any additional processed values.
 */
export function totalFromGroupBy<T extends Record<string, any>, K extends keyof any>(
	grouped: Record<K, T[]>,
	groupedKey: string,
	totalKeys: {
		key: string;
		preProcessor?: (currVal: T) => any;
		postProcessor?: (currVal: any) => any;
	}[],
	extras?: { key: string; process: (currVal: T[]) => any }[]
) {
	return Object.entries(grouped).reduce((acc, [currKey, currVal]) => {
		const temp: Record<string, any> = {};
		let res: number | "" = "";
		temp[groupedKey] = currKey;
		totalKeys.forEach((totalKey) => {
			res = (currVal as T[])
				.map((i) => i[totalKey.key])
				.reduce((a, b) => {
					if (totalKey.preProcessor) {
						b = totalKey.preProcessor(b);
					}
					if (b === null || b === undefined || isNaN(b) || b === "") {
						return a;
					}
					return a + b;
				}, 0);
			temp[totalKey.key] = totalKey.postProcessor ? totalKey.postProcessor(res) : res;
		});
		extras?.forEach(({ key, process }) => {
			temp[key] = process(currVal as T[]);
		});
		acc.push(temp);
		return acc;
	}, [] as Record<string, any>[]);
}

/**
 * Calculates the average values from the grouped data based on the specified keys, with optional pre and post-processing.
 *
 * @param {Record<K, T[]>} grouped - The grouped data to calculate averages from
 * @param {string} groupedKey - The key representing the grouping in the result
 * @param {{key: string; preProcessor?: (currVal: any) => any; postProcessor?: (currVal: any) => any}[]} averageKeys - The keys to calculate averages for, with optional pre and post-processing functions
 * @param {{ key: string; process: (currVal: T[]) => any }[]} [extras] - Extra data to include in the result, with processing functions
 * @return {object[]} An array of objects representing the calculated averages and extra data
 */
export function averageFromGroupBy<T extends Record<string, any>, K extends keyof any>(
	grouped: Record<K, T[]>,
	groupedKey: string,
	averageKeys: {
		key: string;
		preProcessor?: (currVal: any) => any;
		postProcessor?: (currVal: any) => any;
	}[],
	extras?: { key: string; process: (currVal: T[]) => any }[]
) {
	return Object.entries(grouped).reduce((acc, [currKey, currVal]) => {
		const temp: Record<string, any> = {};
		temp[groupedKey] = currKey;
		averageKeys.forEach((averageKey) => {
			let counter = 0;
			const res =
				(currVal as T[])
					.map((i) => i[averageKey.key])
					.reduce((a, b) => {
						if (averageKey.preProcessor) {
							b = averageKey.preProcessor(b);
						}
						if (b === null || b === undefined || isNaN(b) || b === "") {
							return a;
						}
						counter = counter + 1;
						return a + b;
					}, 0) / counter;
			temp[averageKey.key] = averageKey.postProcessor ? averageKey.postProcessor(res) : res;
		});
		extras?.forEach(({ key, process }) => {
			temp[key] = process(currVal as T[]);
		});
		acc.push(temp);
		return acc;
	}, [] as Record<string, any>[]);
}

/**
 * Adds a total line to the given array based on the specified keys.
 *
 * @param {T[]} arr - the input array
 * @param {string[]} totalKeys - the keys to calculate the total
 * @return {T[]} the array with the added total line
 */
export function addTotalLine<T, K extends keyof T>(
	arr: T[],
	totalKeys: {
		key: K;
		preProcessor?: (currVal: any) => any;
		postProcessor?: (currVal: any) => any;
	}[]
) {
	const res = totalKeys.reduce((totalRow, totalKey) => {
		let temp;
		let res: number | "" = "";
		arr.forEach((row) => {
			temp = row[totalKey.key];
			if (totalKey.preProcessor) {
				temp = totalKey.preProcessor(row[totalKey.key]);
			}
			res = (+res || 0) + (+temp || 0);
		});
		totalRow[totalKey.key] = totalKey.postProcessor ? totalKey.postProcessor(+res) : +res;
		return totalRow;
	}, {} as T);
	return [...arr, res];
}

export function guessMonth(name: string, previous: FileMonth[]) {
	const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
	const reg = /\d{1,2}-\d{1,2}-\d{4}/g;
	const matches = name.match(reg);
	const res: FileMonth = {
		name,
		digit: null,
		month: null,
	};
	if (matches && matches[0] && matches[1]) {
		if (matches[0] === matches[1]) {
			res.digit = parseInt(matches[0].split("-")[1]);
		} else {
			res.digit = parseInt(matches[0].split("-")[1]);
		}
	}
	months.some((m) => {
		if (name.toLocaleLowerCase().includes(m.toLocaleLowerCase())) res.month = m;
		return name.toLocaleLowerCase().includes(m.toLocaleLowerCase());
	});
	return res;
}

export function unixFromToFromYear(year: number) {
	return {
		from: unixTimeStamp(new Date(year, 0, 1)),
		to: unixTimeStamp(new Date(year, 11, 31)),
	};
}
