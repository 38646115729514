import { useQuery } from "react-query";
import { TargetGroups } from "../../types/types";
import { api } from "../api";
import { types } from "../../constants";

interface GetUnitGroupsRes {
	data: TargetGroups[];
}

export const getUnitGroups = (): Promise<GetUnitGroupsRes> => {
	return api.get(`wialon/unitgroups`);
};

export const useUnitGroups = (options?: { then?: (x: GetUnitGroupsRes) => any }) => {
	const cacheKey: (string | Record<string, number | string | Date>)[] = [types.UNIT_GROUPS, types.ALL];
	const query = useQuery(
		cacheKey,
		() =>
			getUnitGroups().then((unit) => {
				options && options.then && options.then(unit);
				return unit;
			}),
		{ staleTime: 1000 * 5 }
	);
	return query;
};
