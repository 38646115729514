import { Schedule } from "../../types/types";
import { api } from "../api";

interface PatchScheduleRes {
	data: Schedule[];
}

export const patchSchedule = (props: { id: number; data: Partial<Schedule> }): Promise<PatchScheduleRes> => {
	return api.patch(`schedule/${props.id}`, props.data);
};

export const upsertSchedule = (props: { data: Partial<Schedule> }): Promise<PatchScheduleRes> => {
	return api.patch(`schedule/upsert`, props.data);
};

export const upsertSchedules = (data: Record<string, any>[]): Promise<PatchScheduleRes[]> => {
	return api.patch("schedule/upsertMany", data);
};
