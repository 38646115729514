import { Container, Title, Group, Button, createStyles, rem, Text } from "@mantine/core";
const useStyles = createStyles((theme) => ({
	root: {
		paddingTop: rem(80),
		paddingBottom: rem(80),
	},

	label: {
		textAlign: "center",
		fontWeight: 900,
		fontSize: rem(220),
		lineHeight: 1,
		marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
		color: theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2],

		[theme.fn.smallerThan("sm")]: {
			fontSize: rem(120),
		},
	},

	title: {
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
		textAlign: "center",
		fontWeight: 900,
		fontSize: rem(38),

		[theme.fn.smallerThan("sm")]: {
			fontSize: rem(32),
		},
	},

	description: {
		maxWidth: rem(500),
		margin: "auto",
		marginTop: theme.spacing.xl,
		marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
	},
}));

export function ErrorPage({ type }: { type: "credentials" | "session" | "user" }) {
	const { classes } = useStyles();
	return (
		<Container className={classes.root}>
			<div className={classes.label}>401</div>
			<Title className={classes.title}>You have found a secret place.</Title>
			<Text color="dimmed" size="lg" align="center" className={classes.description}>
				Unfortunately, this is only a 401 page. You are not authorized to view this service.
			</Text>
			{type === "credentials" && (
				<Text color="dimmed" size="lg" align="center" className={classes.description}>
					This service is only accsessible through the monitoring service.
				</Text>
			)}
			{type === "user" && (
				<Text color="dimmed" size="lg" align="center" className={classes.description}>
					If you think this is a mistake, please contact your administrator.
				</Text>
			)}
			{type === "session" && (
				<Text color="dimmed" size="lg" align="center" className={classes.description}>
					Your session has expired. Use the monitoring service and start a new session.
				</Text>
			)}
			<Group position="center">
				<Button component="a" variant="subtle" size="md" href="https://wialon.atsuae.net">
					Back to monitoring service
				</Button>
			</Group>
		</Container>
	);
}
