import { useQuery } from "react-query";
import { api } from "../api";
import { types } from "../../constants";

interface GetSessionAuthRes {
	data: number;
}

export const getSessionAuth = (sid: string | null): Promise<GetSessionAuthRes> => {
	return api.get(`auth/session/${sid}`);
};

export const useSessionAuth = (sid: string | null, options?: { then?: (x: GetSessionAuthRes) => any }) => {
	const cacheKey: (string | Record<string, number | string | Date>)[] = [types.AUTH_SESSION];
	if (sid) cacheKey.push(sid);
	const query = useQuery(
		cacheKey,
		() =>
			getSessionAuth(sid).then((res) => {
				options && options.then && options.then(res);
				return res;
			}),
		{ enabled: sid !== null, staleTime: 1000 * 5 }
	);
	return query;
};
