import {
	ActionIcon,
	Button,
	Container,
	Divider,
	Grid,
	LoadingOverlay,
	MultiSelect,
	NumberInput,
	Select,
	Tooltip,
} from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { TbPlus, TbX } from "react-icons/tb";
import {
	AxiosError,
	deleteSchedule,
	Interval,
	reportsData,
	ScheduleInput,
	types,
	upsertSchedules,
	useSchedules,
} from "../common";
import { useStoreContext } from "../context";
import { useForm } from "@mantine/form";
import { enumToSelectData } from "../utils";
import { Fragment, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDisclosure, useListState } from "@mantine/hooks";
import { ConfirmModal } from "./ConfirmModal";

export function ScheduleForm() {
	const { unitGroups, driverGroups, unitGroupsLoaded, driverGroupsLoaded } = useStoreContext();
	const queryClient = useQueryClient();
	const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
	const [templateFlags, handlers] = useListState<{ cost?: boolean; drivers?: boolean }>([]);
	const [emails, setEmails] = useState<string[]>([]);
	const [confirmOpened, { open: confirmOpen, close: confirmClose }] = useDisclosure(false);
	const [toBeDeleted, setToBeDeleted] = useState<number>(0);
	const emailRegexp = new RegExp("^[a-zA-Z0-9]+(?:.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:.[a-zA-Z0-9]+)*$", "gm");

	const defaultValues = {
		id: undefined,
		name: "",
		objectId: `${process.env.REACT_APP_DEFAULT_UNIT_GROUP_ID}`,
		interval: Interval.MONTHLY,
		time: "",
		sendTo: "",
		cc: undefined,
		petrolCost: 0,
		dieselCost: 0,
	};

	const { data, isLoading } = useSchedules();

	useEffect(() => {
		if (data?.data) {
			setSelectedTemplates(data.data.map((s) => s.name));
			const tempEmails: string[] = [];
			const temp: ScheduleInput[] = data.data.map((s) => ({
				id: s.id,
				name: s.name,
				objectId: s.objectId,
				interval: s.interval,
				time: s.time,
				sendTo: s.sendTo,
				cc: s.cc,
				petrolCost: s.petrolCost === null ? 0 : s.petrolCost,
				dieselCost: s.dieselCost === null ? 0 : s.dieselCost,
			}));
			// temp.push(defaultValues);
			data.data.forEach((s, index) => {
				const template = reportsData.find((r) => r.service === s.name);
				s.sendTo.split(",").forEach((email) => {
					if (!tempEmails.includes(email)) {
						tempEmails.push(email);
					}
				});
				s.cc?.split(",").forEach((email) => {
					if (!tempEmails.includes(email)) {
						tempEmails.push(email);
					}
				});
				handlers.setItem(index, {
					cost: template?.cost ? true : false,
					drivers: template?.drivers ? true : false,
				});
			});
			setEmails(tempEmails);
			form.setFieldValue("schedules", temp);
			form.resetDirty({ schedules: temp });
		}
	}, [data]);

	const form = useForm<{ schedules: ScheduleInput[] }>({
		initialValues: {
			schedules: [],
		},
	});

	const { mutate: upsertMutate, isLoading: upsertLoading } = useMutation(upsertSchedules, {
		onSuccess: () => {
			queryClient.invalidateQueries([types.SCHEDULE, types.ALL]);
		},
		onError: (data: AxiosError) => {
			console.log(data.response.data.message);
		},
	});

	const { mutate: deleteMutate, isLoading: deleteLoading } = useMutation(deleteSchedule, {
		onSuccess: () => {
			queryClient.invalidateQueries([types.SCHEDULE, types.ALL]);
		},
		onError: (data: AxiosError) => {
			console.log(data.response.data.message);
		},
	});

	function onSubmit(values: { schedules: ScheduleInput[] }) {
		const newValues = values.schedules
			.filter((s) => s.name !== "")
			.map((s) => ({
				...s,
				time: s.time === "" ? "00:00" : s.time,
				cc: s.cc === "" ? null : s.cc,
				sendTo: s.sendTo === "" ? null : s.sendTo,
				petrolCost: s.petrolCost === 0 ? null : s.petrolCost,
				dieselCost: s.dieselCost === 0 ? null : s.dieselCost,
			}));
		// console.log(values, newValues);
		upsertMutate(newValues);
	}

	return (
		<Container>
			<LoadingOverlay
				visible={!unitGroupsLoaded || !driverGroupsLoaded || isLoading || upsertLoading || deleteLoading}
			/>
			<form onSubmit={form.onSubmit((values) => onSubmit(values))}>
				<Grid gutter={"xs"} columns={24} align="center">
					{form.values.schedules.map((schedule, index) => (
						<Fragment key={`schedule-${index}`}>
							<Grid.Col span={9}>
								<Select
									withinPortal
									data={reportsData
										.filter((r) => r.service)
										.map((r) => ({
											value: r.service ?? r.value,
											label: r.label,
											disabled: selectedTemplates.includes(r.service ?? r.value),
										}))}
									{...form.getInputProps(`schedules.${index}.name`)}
									onChange={(e) => {
										if (e) {
											const template = reportsData.find((r) => r.service === e);
											setSelectedTemplates([
												...selectedTemplates.filter((t) => t !== form.values.schedules[index].name),
												e,
											]);
											form.setFieldValue(`schedules.${index}.interval`, template?.group as Interval);
											handlers.setItem(index, {
												cost: template?.cost ? true : false,
												drivers: template?.drivers ? true : false,
											});
										}
										form.setFieldValue(`schedules.${index}.name`, e);
									}}
								/>
							</Grid.Col>
							<Grid.Col span={6}>
								<Select
									withinPortal
									data={templateFlags[index]?.drivers ? driverGroups : unitGroups}
									{...form.getInputProps(`schedules.${index}.objectId`)}
								/>
							</Grid.Col>
							<Grid.Col span={4}>
								<Select
									disabled
									withinPortal
									data={enumToSelectData(Interval).map((i) => ({
										value: i.value,
										label: i.label,
										// disabled: [Interval.MINUTE, Interval.HOUR, Interval.YEAR].includes(i.value as Interval),
									}))}
									{...form.getInputProps(`schedules.${index}.interval`)}
								/>
							</Grid.Col>
							<Grid.Col span={4}>
								<TimeInput {...form.getInputProps(`schedules.${index}.time`)} />
							</Grid.Col>
							<Grid.Col span={1}>
								<ActionIcon
									mx="auto"
									color="red"
									c="red"
									onClick={() => {
										//TODO: Add confirmation modal
										if (schedule.id === undefined) form.removeListItem("schedules", index);
										else {
											confirmOpen();
											setToBeDeleted(schedule.id);
										}
									}}
								>
									<TbX />
								</ActionIcon>
							</Grid.Col>
							<Grid.Col span={!templateFlags[index]?.cost ? 12 : 10}>
								<MultiSelect
									data={emails.map((e) => ({
										value: e,
										label: e,
										disabled: form.values.schedules[index].cc?.split(",").includes(e),
									}))}
									placeholder="Send To"
									searchable
									creatable
									getCreateLabel={(query) => `+ Add ${query}`}
									onCreate={(query) => {
										if (emailRegexp.test(query)) {
											// const item = { value: query, label: query };
											setEmails((current) => [...current, query]);
											return query;
										}
									}}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											e.preventDefault();
										}
									}}
									{...form.getInputProps(`schedules.${index}.sendTo`)}
									value={form.values.schedules[index].sendTo?.split(",") || []}
									onChange={(v) => {
										form.setFieldValue(`schedules.${index}.sendTo`, v.join(","));
									}}
								/>
							</Grid.Col>
							<Grid.Col span={!templateFlags[index]?.cost ? 11 : 9}>
								<MultiSelect
									data={emails.map((e) => ({
										value: e,
										label: e,
										disabled: form.values.schedules[index].sendTo?.split(",").includes(e),
									}))}
									placeholder="cc"
									searchable
									creatable
									getCreateLabel={(query) => `+ Add ${query}`}
									onCreate={(query) => {
										if (emailRegexp.test(query)) {
											// const item = { value: query, label: query };
											setEmails((current) => [...current, query]);
											return query;
										}
									}}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											e.preventDefault();
										}
									}}
									{...form.getInputProps(`schedules.${index}.cc`)}
									value={form.values.schedules[index].cc?.split(",") || []}
									onChange={(v) => {
										form.setFieldValue(`schedules.${index}.cc`, v.join(","));
									}}
								/>
							</Grid.Col>
							{templateFlags[index]?.cost && (
								<Tooltip label={"Petrol Cost"}>
									<Grid.Col span={2}>
										<NumberInput
											hideControls
											placeholder="Cost"
											precision={2}
											step={0.05}
											{...form.getInputProps(`schedules.${index}.petrolCost`)}
										/>
									</Grid.Col>
								</Tooltip>
							)}
							{templateFlags[index]?.cost && (
								<Tooltip label={"Diesel Cost"}>
									<Grid.Col span={2}>
										<NumberInput
											hideControls
											placeholder="Cost"
											precision={2}
											step={0.05}
											{...form.getInputProps(`schedules.${index}.dieselCost`)}
										/>
									</Grid.Col>
								</Tooltip>
							)}
							<Grid.Col span={24}>
								<Divider orientation="horizontal" />
							</Grid.Col>
						</Fragment>
					))}

					<Grid.Col offset={23} span={1}>
						<ActionIcon my="sm" onClick={() => form.insertListItem("schedules", { ...defaultValues })}>
							<TbPlus />
						</ActionIcon>
					</Grid.Col>
				</Grid>
				<Button fullWidth type="submit">
					Save
				</Button>
			</form>
			<ConfirmModal opened={confirmOpened} onClose={confirmClose} onConfirm={() => deleteMutate(toBeDeleted)} />
		</Container>
	);
}
