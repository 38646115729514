import { Button, Group, Modal, ModalProps, Text } from "@mantine/core";

export function ConfirmModal({ onConfirm, ...props }: ModalProps & { onConfirm: () => void }) {
	return (
		<Modal {...props} title="Confirm">
			<Text>Are you sure you want to delete this item?</Text>
			<Group mt="lg" position="right">
				<Button variant="default" onClick={props.onClose}>
					Cancel
				</Button>
				<Button
					onClick={() => {
						onConfirm();
						props.onClose();
					}}
				>
					Confirm
				</Button>
			</Group>
		</Modal>
	);
}
