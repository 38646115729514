import axios from "axios";
import { useQuery } from "react-query";
import { types } from "../../constants";

const api = axios.create({
	baseURL: process.env.REACT_APP_WIALON_API_URL,
	// withCredentials: true,
});

interface GetWialonEventsRes {
	data: number;
}

export const getWialonEvents = (sid: string | null): Promise<GetWialonEventsRes> => {
	const params = new URLSearchParams();
	// params.append("params", JSON.stringify({ type: 1 }));
	// params.append("svc", "core/get_account_data");
	if (sid) params.append("sid", sid);
	return api.get("/avl_evts?" + params.toString());
};

export const useWialonEvents = (sid: string | null, options?: { then?: (x: GetWialonEventsRes) => any }) => {
	const cacheKey: (string | Record<string, number | string | Date>)[] = [types.WIALON_EVENTS];
	if (sid) cacheKey.push(sid);
	const query = useQuery(
		cacheKey,
		() =>
			getWialonEvents(sid).then((res) => {
				options && options.then && options.then(res);
				return res;
			}),
		{ enabled: sid !== null, staleTime: 1000 * 5 }
	);
	return query;
};
