import { addTotalLine, averageFromGroupBy, groupBy, months, totalFromGroupBy } from "../utils";

export function yearlyProductivityFormatter(data: Record<any, Record<string, any>[]>) {
	const initial = Object.entries(data).reduce((acc, [key, value]) => {
		const res: Record<string, string | number> = {};
		res["Vehicle"] = key;
		value.forEach((row, i) => {
			if (i === 0) {
				res["Category"] = row[`Category`];
				res["Type"] = row[`Type`];
				res["Plate"] = row[`Plate`];
			}
			if (!res["Category"]) res["Category"] = row[`Category`];
			if (!res["Type"]) res["Type"] = row[`Type`];
			if (!res["Plate"]) res["Plate"] = row[`Plate`];
		});
		let temp = 0;
		let tempCount = 0;
		//FIXME: Why named extendedRow???
		const extendedRow = value.reduce((acc, row) => {
			Object.keys(row).forEach((key) => {
				if (!acc[key]) acc[key] = row[key];
				else if (!isNaN(row[key])) {
					acc[key] = +((acc[key] ?? 0) + row[key]).toFixed(2);
				}
			});
			return acc;
		}, {} as Record<string, any>);
		Array(12)
			.fill("")
			.forEach((_, i) => {
				res[months[i]] = extendedRow[`${i + 1}_Productivity`] ?? "";
				if (extendedRow[`${i + 1}_Productivity`]) {
					temp += +extendedRow[`${i + 1}_Productivity`]?.slice(0, -2) ?? 0;
					tempCount++;
				}
				if ((i + 1) % 3 === 0) {
					res[`Q${(i + 1) / 3}`] = tempCount ? `${(temp / tempCount).toFixed(2)} %` : "";
					temp = 0;
					tempCount = 0;
				}
			});
		acc.push(res);
		return acc;
	}, [] as Record<string, string | number>[]);

	const final = averageFromGroupBy(
		groupBy(initial, (i) => i["Category"]),
		"Category",
		[
			...Array(12)
				.fill("")
				.map((_, i) => ({
					key: `${months[i]}`,
					preProcessor: (currVal: any) => +currVal?.slice(0, -2),
					postProcessor: (currVal: any) => (currVal ? `${currVal?.toFixed(2)} %` : ""),
				})),
		]
	);

	return [{ "0_Details": initial, "1_Summary": final }];
}

export function yearlyEngineHoursFormatter(data: Record<any, Record<string, any>[]>) {
	const initial = Object.entries(data).reduce((acc, [key, value]) => {
		const res: Record<string, string | number> = {};
		res["Vehicle"] = key;
		value.forEach((row, i) => {
			if (i === 0) {
				res["Category"] = row[`Category`];
				res["Type"] = row[`Type`];
				res["Plate"] = row[`Plate`];
			}
			if (!res["Category"]) res["Category"] = row[`Category`];
			if (!res["Type"]) res["Type"] = row[`Type`];
			if (!res["Plate"]) res["Plate"] = row[`Plate`];
		});
		let temp = 0;
		//FIXME: Why named extendedRow???
		const extendedRow = value.reduce((acc, row) => {
			Object.keys(row).forEach((key) => {
				if (!acc[key]) acc[key] = row[key];
				else if (!isNaN(row[key])) {
					acc[key] = +((acc[key] ?? 0) + row[key]).toFixed(2);
				}
			});
			return acc;
		}, {} as Record<string, any>);
		Array(12)
			.fill("")
			.forEach((_, i) => {
				res[months[i]] = extendedRow[`${i + 1}_Total`] ?? "";
				if (extendedRow[`${i + 1}_Total`]) {
					temp += +extendedRow[`${i + 1}_Total`] ?? 0;
				}
				if ((i + 1) % 3 === 0) {
					res[`Q${(i + 1) / 3}`] = temp ? temp : 0;
					temp = 0;
				}
			});
		acc.push(res);
		return acc;
	}, [] as Record<string, string | number>[]);

	const final = totalFromGroupBy(
		groupBy(initial, (i) => i["Category"]),
		"Category",
		[
			...Array(12)
				.fill("")
				.map((_, i) => ({
					key: `${months[i]}`,
					preProcessor: (currVal: any) => +currVal,
					postProcessor: (currVal: any) => (currVal ? currVal : 0),
				})),
		]
	);

	return [{ "0_main": initial, "1_Summary": final }];
}

export function yearlyMileageFormatter(data: Record<any, Record<string, any>[]>) {
	const initial = Object.entries(data).reduce((acc, [key, value]) => {
		const res: Record<string, string | number> = {};
		res["Vehicle"] = key;
		value.forEach((row, i) => {
			if (i === 0) {
				res["Category"] = row[`Category`];
				res["Type"] = row[`Type`];
				res["Plate"] = row[`Plate`];
			}
			if (!res["Category"]) res["Category"] = row[`Category`];
			if (!res["Type"]) res["Type"] = row[`Type`];
			if (!res["Plate"]) res["Plate"] = row[`Plate`];
		});
		let temp = 0;
		//FIXME: Why named extendedRow???
		const extendedRow = value.reduce((acc, row) => {
			Object.keys(row).forEach((key) => {
				if (!acc[key]) acc[key] = row[key];
				else if (!isNaN(row[key])) {
					acc[key] = +((acc[key] ?? 0) + row[key]).toFixed(2);
				}
			});
			return acc;
		}, {} as Record<string, any>);
		Array(12)
			.fill("")
			.forEach((_, i) => {
				res[months[i]] = extendedRow[`${i + 1}_Total`] ?? "";
				if (extendedRow[`${i + 1}_Total`]) {
					temp += +extendedRow[`${i + 1}_Total`] ?? 0;
				}
				if ((i + 1) % 3 === 0) {
					res[`Q${(i + 1) / 3}`] = temp ? temp : 0;
					temp = 0;
				}
			});
		acc.push(res);
		return acc;
	}, [] as Record<string, string | number>[]);

	const final = totalFromGroupBy(
		groupBy(initial, (i) => i["Category"]),
		"Category",
		[
			...Array(12)
				.fill("")
				.map((_, i) => ({
					key: `${months[i]}`,
					preProcessor: (currVal: any) => +currVal,
					postProcessor: (currVal: any) => (currVal ? currVal : 0),
				})),
		]
	);

	return [{ "0_main": initial, "1_Summary": final }];
}

export function yearlyRuuningAfterDutyOutOfGeoFenceFormatter(data: Record<any, Record<string, any>[]>) {
	const initial = Object.entries(data).reduce((acc, [key, value]) => {
		const res: Record<string, string | number> = {};
		res["Vehicle"] = key;
		value.forEach((row, i) => {
			if (i === 0) {
				res["Category"] = row[`Category`];
				res["Type"] = row[`Type`];
				res["Plate"] = row[`Plate`];
			}
			if (!res["Category"]) res["Category"] = row[`Category`];
			if (!res["Type"]) res["Type"] = row[`Type`];
			if (!res["Plate"]) res["Plate"] = row[`Plate`];
		});
		let temp = 0;
		//FIXME: Why named extendedRow???
		const extendedRow = value.reduce((acc, row) => {
			Object.keys(row).forEach((key) => {
				if (!acc[key]) acc[key] = row[key];
				else if (!isNaN(row[key])) {
					acc[key] = +((acc[key] ?? 0) + row[key]).toFixed(2);
				}
			});
			return acc;
		}, {} as Record<string, any>);
		Array(12)
			.fill("")
			.forEach((_, i) => {
				res[months[i]] = extendedRow[`${i + 1}_Total`] ?? "";
				if (extendedRow[`${i + 1}_Total`]) {
					temp += +extendedRow[`${i + 1}_Total`] ?? 0;
				}
				if ((i + 1) % 3 === 0) {
					res[`Q${(i + 1) / 3}`] = temp ? temp : 0;
					temp = 0;
				}
			});
		acc.push(res);
		return acc;
	}, [] as Record<string, string | number>[]);

	const final = totalFromGroupBy(
		groupBy(initial, (i) => i["Category"]),
		"Category",
		[
			...Array(12)
				.fill("")
				.map((_, i) => ({
					key: `${months[i]}`,
					preProcessor: (currVal: any) => +currVal,
					postProcessor: (currVal: any) => (currVal ? currVal : 0),
				})),
		]
	);

	return [{ "0_main": initial, "1_Summary": final }];
}

export function yearlyParkedOnDutyOutOfGeoFenceFormatter(data: Record<any, Record<string, any>[]>) {
	const initial = Object.entries(data).reduce((acc, [key, value]) => {
		const res: Record<string, string | number> = {};
		res["Vehicle"] = key;
		value.forEach((row, i) => {
			if (i === 0) {
				res["Category"] = row[`Category`];
				res["Type"] = row[`Type`];
				res["Plate"] = row[`Plate`];
			}
			if (!res["Category"]) res["Category"] = row[`Category`];
			if (!res["Type"]) res["Type"] = row[`Type`];
			if (!res["Plate"]) res["Plate"] = row[`Plate`];
		});
		let temp = 0;
		//FIXME: Why named extendedRow???
		const extendedRow = value.reduce((acc, row) => {
			Object.keys(row).forEach((key) => {
				if (!acc[key]) acc[key] = row[key];
				else if (!isNaN(row[key])) {
					acc[key] = +((acc[key] ?? 0) + row[key]).toFixed(2);
				}
			});
			return acc;
		}, {} as Record<string, any>);
		Array(12)
			.fill("")
			.forEach((_, i) => {
				res[months[i]] = extendedRow[`${i + 1}_Total`] ?? "";
				if (extendedRow[`${i + 1}_Total`]) {
					temp += +extendedRow[`${i + 1}_Total`] ?? 0;
				}
				if ((i + 1) % 3 === 0) {
					res[`Q${(i + 1) / 3}`] = temp ? temp : 0;
					temp = 0;
				}
			});
		acc.push(res);
		return acc;
	}, [] as Record<string, string | number>[]);

	const final = totalFromGroupBy(
		groupBy(initial, (i) => i["Category"]),
		"Category",
		[
			...Array(12)
				.fill("")
				.map((_, i) => ({
					key: `${months[i]}`,
					preProcessor: (currVal: any) => +currVal,
					postProcessor: (currVal: any) => (currVal ? currVal : 0),
				})),
		]
	);
	return [{ "0_main": initial, "1_Summary": final }];
}

export function yearlyIdleUnitsFormatter(data: Record<any, Record<string, any>[]>) {
	const initial = Object.entries(data).reduce((acc, [key, value]) => {
		const res: Record<string, string | number> = {};
		res["Vehicle"] = key;
		value.forEach((row, i) => {
			if (i === 0) {
				res["Category"] = row[`Category`];
			}
			if (!res["Category"]) res["Category"] = row[`Category`];
		});
		let temp = 0;

		//FIXME: Why named extendedRow???
		const extendedRow = value.reduce((acc, row) => {
			Object.keys(row).forEach((key) => {
				if (!acc[key]) acc[key] = row[key];
				else if (!isNaN(row[key])) {
					acc[key] = +((acc[key] ?? 0) + row[key]).toFixed(2);
				}
			});
			return acc;
		}, {} as Record<string, any>);
		Array(12)
			.fill("")
			.forEach((_, i) => {
				res[`${months[i]} Idle hours`] = extendedRow[`${i + 1}_Idle hours`] ?? "";
				if (extendedRow[`${i + 1}_Idle hours`]) {
					temp += +extendedRow[`${i + 1}_Idle hours`] ?? 0;
				}
				if ((i + 1) % 3 === 0) {
					res[`Q${(i + 1) / 3} Idle hours`] = temp ? +temp.toFixed(2) : 0;
					temp = 0;
				}
			});
		temp = 0;
		Array(12)
			.fill("")
			.forEach((_, i) => {
				res[`${months[i]} Cost`] = extendedRow[`${i + 1}_Cost`] ?? "";
				if (extendedRow[`${i + 1}_Cost`]) {
					temp += +extendedRow[`${i + 1}_Cost`] ?? 0;
				}
				if ((i + 1) % 3 === 0) {
					res[`Q${(i + 1) / 3} Cost`] = temp ? +temp.toFixed(2) : 0;
					temp = 0;
				}
			});
		if (res["Vehicle"]) acc.push(res);
		return acc;
	}, [] as Record<string, string | number>[]);

	const final = totalFromGroupBy(
		groupBy(initial, (i) => i["Category"]),
		"Category",
		[
			...Array(12)
				.fill("")
				.map((_, i) => ({
					key: `${months[i]} Idle hours`,
					preProcessor: (currVal: any) => +currVal,
					postProcessor: (currVal: any) => (currVal ? +currVal.toFixed(2) : 0),
				})),
			...Array(12)
				.fill("")
				.map((_, i) => ({
					key: `${months[i]} Cost`,
					preProcessor: (currVal: any) => +currVal,
					postProcessor: (currVal: any) => (currVal ? +currVal.toFixed(2) : 0),
				})),
		]
		// [
		// 	...Array(4)
		// 		.fill("")
		// 		.map((_, i) => ({
		// 			key: `Q${i + 1} Idle hours`,
		// 			process: (currVal: any[]) => {
		// 				let idle = 0;
		// 				currVal.forEach((val) => {

		// 					idle = idle + val[`${months[i]} Idle hours`];
		// 					if ((i + 1) % 3 === 0) {
		// 						val[`Q${(i + 1) / 3} Idle hours`] = idle ? +idle.toFixed(2) : 0;
		// 						idle = 0;
		// 					}
		// 				});
		// 			},
		// 		})),
		// 	...Array(4)
		// 		.fill("")
		// 		.map((_, i) => ({
		// 			key: `Q${i + 1} Cost`,
		// 			process: (currVal: any[]) => {
		// 				let cost = 0;
		// 				currVal.forEach((val) => {
		// 					cost = cost + val[`${months[i]} Cost`];
		// 					if ((i + 1) % 3 === 0) {
		// 						val[`Q${(i + 1) / 3} Cost`] = cost ? +cost.toFixed(2) : 0;
		// 						cost = 0;
		// 					}
		// 				});
		// 			},
		// 		})),
		// ]
	);

	return [{ "0_main": initial, "1_Summary": final }];

	// return [
	// 	{
	// 		"0_main": addTotalLine(initial, [
	// 			...Array(12)
	// 				.fill("")
	// 				.map((_, i) => ({ key: `${months[i]} Idle hours` })),
	// 			...Array(12)
	// 				.fill("")
	// 				.map((_, i) => ({ key: `${months[i]} Cost` })),
	// 			...Array(4)
	// 				.fill("")
	// 				.map((_, i) => ({ key: `Q${i} Idle hours` })),
	// 			...Array(4)
	// 				.fill("")
	// 				.map((_, i) => ({ key: `Q${i} Cost` })),
	// 		]),
	// 		"1_Summary": addTotalLine(final, [
	// 			...Array(12)
	// 				.fill("")
	// 				.map((_, i) => ({ key: `${months[i]} Idle hours` })),
	// 			...Array(12)
	// 				.fill("")
	// 				.map((_, i) => ({ key: `${months[i]} Cost` })),
	// 			// ...Array(4)
	// 			// 	.fill("")
	// 			// 	.map((_, i) => ({ key: `Q${i} Idle hours` })),
	// 			// ...Array(4)
	// 			// 	.fill("")
	// 			// 	.map((_, i) => ({ key: `Q${i} Cost` })),
	// 		]),
	// 	},
	// ];
}
