import { utils, writeFileXLSX, read, WorkSheet } from "xlsx";
import { dataExplorer, maxColWidth } from "../../utils";
import { ReportData } from "../../types";

type ReportFormUnix = {
	reportTemplate: string;
	targetGroup?: string | null;
	from?: number;
	to?: number;
	cost?: number;
};

export function generateAndDownloadExcel(
	data: Record<string, any[]>[],
	values: ReportFormUnix,
	fileName: string,
	titleAddtion: string,
	colsOrder?: string[][],
	selectedReport?: ReportData,
	onSuccess?: () => void
) {
	const wb = utils.book_new();
	Object.entries(
		data.reduce((acc, sheet) => {
			Object.entries(sheet).forEach(([key, sheet]) => {
				acc[key] = sheet;
			});
			return acc;
		}, {} as Record<string, any[]>)
	).forEach(([key, sheet], index) => {
		const { colsKeys, colsLength, data } = dataExplorer(sheet, colsOrder ? colsOrder[index] : undefined);
		const initWs = utils.aoa_to_sheet([
			[`${selectedReport?.label || values.reportTemplate} - ${titleAddtion}`, Array(colsLength - 1).fill("")],
		]);
		const ws = utils.sheet_add_aoa(initWs, [colsKeys, ...data], { origin: -1 });
		ws["!cols"] = maxColWidth(sheet, colsOrder ? colsOrder[index] : undefined);
		ws["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: colsLength - 1 } }];
		ws["!autofilter"] = { ref: utils.encode_range({ s: { c: 0, r: 1 }, e: { c: colsLength - 1, r: 1 } }) };
		utils.book_append_sheet(wb, ws, `${key.split("_")[1] || selectedReport?.label || values.reportTemplate}`);
	});
	writeFileXLSX(wb, `${fileName}.xlsx`);
	if (onSuccess) onSuccess();
}

const ec = (r: number, c: number) => {
	return utils.encode_cell({ r: r, c: c });
};

const delete_row = (ws: WorkSheet, row_index: number) => {
	let range = utils.decode_range(ws["!ref"] as string);
	for (var R = row_index; R < range.e.r; ++R) {
		for (var C = range.s.c; C <= range.e.c; ++C) {
			ws[ec(R, C)] = ws[ec(R + 1, C)];
		}
	}
	range.e.r--;
	ws["!ref"] = utils.encode_range(range.s, range.e);
};

export function excelColumnExtractor<K extends string>(
	rowData: ArrayBuffer,
	cols: string[],
	groupingCols: string[],
	month: string,
	sheetName?: string
) {
	const workbook = read(rowData);
	const sheet = workbook.Sheets[sheetName ? sheetName : workbook.SheetNames[0]];
	delete_row(sheet, 0);
	const data = utils.sheet_to_json<Record<string, any>>(sheet, {
		header: 2,
	});
	return data.map((row) =>
		cols.reduce(
			(acc, col) => ({ ...acc, [groupingCols.includes(col) ? col : `${month}_${col}`]: row[col] }),
			{} as Record<K, any>
		)
	);
}

export function getColNames(rowData: ArrayBuffer, sheetName?: string) {
	const workbook = read(rowData);
	const sheet = workbook.Sheets[sheetName ? sheetName : workbook.SheetNames[0]];
	delete_row(sheet, 0);
	const data = utils.sheet_to_json<string[]>(sheet, {
		header: 1,
	});
	return data[0];
}
