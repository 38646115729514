import axios from "axios";

export const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	// withCredentials: true,
});

export interface AxiosError {
	response: {
		data: {
			message: string | string[];
		};
	};
}
// export default api;
