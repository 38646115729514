import { ReportData, YearlyReport } from "../../types";
import { months } from "../../utils";
import {
	yearlyEngineHoursFormatter,
	yearlyIdleUnitsFormatter,
	yearlyMileageFormatter,
	yearlyParkedOnDutyOutOfGeoFenceFormatter,
	yearlyProductivityFormatter,
	yearlyRuuningAfterDutyOutOfGeoFenceFormatter,
} from "../formatters";

// TODO: remove the yearly
export const reportsData: ReportData[] = [
	{
		label: "15 mins idle",
		value: "daily15minsidle",
		service: "getDaily15MinsIdle",
		group: "Daily",
		type: "date",
	},
	{
		label: "Mileage between 8 to 5",
		value: "dailymileage8to5",
		service: "getDailyMileage8To5",
		group: "Daily",
		type: "date",
	},
	{
		label: "Extreme Brake",
		value: "dailyextremebrake",
		service: "getDailyExtremeBrake",
		group: "Daily",
		type: "date",
	},
	{
		label: "Extreme Speed",
		value: "dailyextremespeed",
		service: "getDailyExtremeSpeed",
		group: "Daily",
		type: "date",
	},
	{
		label: "Non Reporting",
		value: "nonreporting",
		service: "getNonReporting",
		group: "Daily",
		type: "none",
	},
	{
		label: "Equipment Running Hours",
		value: "monthlyrunnighours",
		service: "getMonthlyRunnigHours",
		group: "Monthly",
		type: "month",
	},
	{
		label: "Mileage",
		value: "monthlymileage",
		service: "getMonthlyMileage",
		group: "Monthly",
		type: "month",
	},
	{
		label: "Idle Drivers",
		value: "monthlyidledrivers",
		service: "getMonthlyIdleDrivers",
		group: "Monthly",
		type: "month",
		cost: true,
	},
	{
		label: "Idle Units",
		value: "monthlyidleunits",
		service: "getMonthlyIdleUnits",
		group: "Monthly",
		type: "month",
		cost: true,
		// disabled: true,
		// yearly: true,
	},
	{
		label: "Vehicles Violations Summary",
		value: "monthlyvehiclesviolationssummary",
		service: "getMonthlyVehiclesViolationsSummary",
		group: "Monthly",
		type: "month",
	},
	{
		label: "Drivers Violations Detailed",
		value: "monthlydriversviolationsdetailed",
		service: "getMonthlyDriversViolationsDetailed",
		group: "Monthly",
		type: "month",
	},
	{
		label: "Productivity",
		value: "monthlyproductivity",
		service: "getMonthlyProductivity",
		group: "Monthly",
		type: "month",
	},
	{
		label: "Driver Rankings",
		value: "monthlydriversranking",
		service: "getMonthlyDriversRanking",
		group: "Monthly",
		type: "month",
		drivers: true,
	},
	{
		label: "Holidays",
		value: "yearlyholidays",
		// service: "getYearlyHolidaysMileage",
		group: "Monthly",
		type: "range",
		limit: "3 months",
		// yearly: true,
	},
	{
		label: "Sundays",
		value: "yearlysundays",
		service: "getYearlySunndaysMileage",
		group: "Monthly",
		type: "month",
		// limit: "3 months",
		// yearly: true,
	},
	// {
	{
		label: "Drivers Eco Driving",
		value: "yearlydriversecodriving",
		group: "Yearly",
		type: "range",
		limit: "3 months",
		disabled: true,
		yearly: true,
	},
	{
		label: "Drivers Violations",
		value: "yearlydriversviolations",
		group: "Yearly",
		type: "range",
		limit: "3 months",
		yearly: true,
	},
	{
		label: "Fuel Consumption Summary",
		value: "yearlyfuelconsumptionsummary",
		group: "Yearly",
		type: "range",
		limit: "3 months",
		cost: true,
		yearly: true,
	},
	{
		label: "Running Mileage Summary",
		value: "yearlyrunningmileagesummary",
		group: "Yearly",
		type: "range",
		limit: "3 months",
		yearly: true,
		disabled: true,
	},
	{
		label: "Running Hours Summary",
		value: "yearlyrunninghourssummary",
		group: "Yearly",
		type: "range",
		limit: "3 months",
		yearly: true,
		disabled: true,
	},
	{
		label: "Engine Hours",
		value: "yearlyhours",
		group: "Yearly",
		type: "range",
		limit: "3 months",
		yearly: true,
		disabled: true,
	},
	{
		label: "Engine Mileage",
		value: "yearlymileage",
		group: "Yearly",
		type: "range",
		limit: "3 months",
		yearly: true,
		disabled: true,
	},
	{
		label: "Unit Running After Duty",
		value: "monthlyrunningafterdutyoutofgeofence",
		service: "getMonthlyRunningAfterDutyOutOfGeofence",
		group: "Monthly",
		type: "month",
		// disabled: true,
		// yearly: true,
	},
	{
		label: "Driver Running After Duty",
		value: "monthlyrunningafterdutydriver",
		service: "getMonthlyRunningAfterDutyDriver",
		group: "Monthly",
		type: "month",
		// disabled: true,
		// yearly: true,
	},
	{
		label: "Parked On Duty Out of Geofence",
		value: "monthlyparkedondutyoutofgeofence",
		service: "getMonthlyParkedOnDutyOutOfGeofence",
		group: "Monthly",
		type: "month",
		// disabled: true,
		// yearly: true,
	},
];

//TODO: remove the yearly
export const reportOrders: Record<string, string[][]> = {
	monthlyrunnighours: [
		["Vehicle", "Plate", "Type", "Category", "Total"],
		["Category", "Total"],
	],
	monthlymileage: [
		["Vehicle", "Plate", "Type", "Category", "Total"],
		["Category", "Total"],
	],
	yearlyProductivity: [
		[
			"Category",
			...Array(52)
				.fill("")
				.reduce((acc, _, i) => {
					if ((i + 1) % 13 === 1) {
						acc.push(`Productivity Q ${Math.floor(i / 13 + 1)}`);
						acc.push(`Idle Q ${Math.floor(i / 13 + 1)}`);
					}
					acc.push(`${i + 1}`);
					return acc;
				}, []),
		],
		[
			"Vehicle",
			"Type",
			"Category",
			...Array(52)
				.fill("")
				.reduce((acc, _, i) => {
					if ((i + 1) % 13 === 1) {
						acc.push(`Productivity Q ${Math.floor(i / 13 + 1)}`);
						acc.push(`Idle Q ${Math.floor(i / 13 + 1)}`);
					}
					acc.push(`${i + 1}`);
					return acc;
				}, []),
		],
	],
	monthlyrunningafterdutyoutofgeofence: [
		["Category", "Total"],
		["Vehicle", "Plate", "Category", "Type", "Total"],
	],
	monthlyparkedondutyoutofgeofence: [
		["Category", "Total"],
		["Vehicle", "Plate", "Category", "Type", "Total"],
	],
	monthlyrunningafterdutydriver: [
		["Driver", "Description", "Phone", "Total"],
		["Driver", "Description", "Phone", "Total"],
		["Driver", "Description", "Phone", "Total"],
	],
	monthlyproductivity: [
		[
			"Category",
			"Productivity",
			"Idle",
			...Array(4)
				.fill("")
				.map((_, i) => `Week ${i + 1}`),
		],
		[
			"Vehicle",
			"Plate",
			"Category",
			"Type",
			"Productivity",
			"Idle",
			...Array(4)
				.fill("")
				.map((_, i) => `Week ${i + 1}`),
		],
	],
	yearlyparkedondutyoutofgeofence: [
		[
			"Category",
			...Array(52)
				.fill("")
				.reduce((acc, _, i) => {
					if ((i + 1) % 13 === 1) {
						acc.push(`Total Q ${Math.floor(i / 13 + 1)}`);
					}
					acc.push(`${i + 1}`);
					return acc;
				}, []),
		],
		[
			"Vehicle",
			"Category",
			...Array(52)
				.fill("")
				.reduce((acc, _, i) => {
					if ((i + 1) % 13 === 1) {
						acc.push(`Total Q ${Math.floor(i / 13 + 1)}`);
					}
					acc.push(`${i + 1}`);
					return acc;
				}, []),
		],
	],
	monthlyvehiclesviolationssummary: [
		[
			"Category",
			"No. of Units",
			"Total Units",
			"Violation percentage",
			"Total Violations",
			...Array(4)
				.fill("")
				.map((_, i) => `Week ${i + 1}`),
		],
	],
	yearlyfuelconsumptionsummary: [
		[
			"Category",
			"No. of Units",
			...Array(4)
				.fill("")
				.map((_, i) => `Mileage Q${i + 1}`),
			...Array(4)
				.fill("")
				.map((_, i) => `Cost Q${i + 1}`),
			"Fuel Rate",
			"Mileage",
			"Mileage Cost",
		],
	],
	yearlyrunningmileagesummary: [
		[
			"Category",
			"No. of Units",
			"Average KM Per month",
			"Average KM Per day",
			"Mileage",
			...Array(4)
				.fill("")
				.map((_, i) => `Q${i + 1}`),
		],
	],
	yearlyrunninghourssummary: [
		[
			"Category",
			"No. of Units",
			"Average KM Per month",
			"Average KM Per day",
			"Hours",
			...Array(4)
				.fill("")
				.map((_, i) => `Q${i + 1}`),
		],
	],
	yearlymileage: [["Vehicle", "Type", "Category", "Total", ...months]],
};

// TODO: productivity weekly
export const yearlyReports: YearlyReport[] = [
	{
		value: "productivity",
		label: "Productivity",
		sheet: "Details",
		cols: ["Vehicle", "Productivity", "Category", "Type", "Plate"],
		groupingCols: ["Vehicle", "Category", "Type", "Plate"],
		mustCols: ["Vehicle", "Productivity"],
		reportColsOrder: ["Vehicle", "Plate", "Category", "Type", ...months],
		formatter: yearlyProductivityFormatter,
	},
	{
		value: "hours",
		label: "Hours",
		sheet: "Main",
		cols: ["Vehicle", "Total", "Category", "Type", "Plate"],
		groupingCols: ["Vehicle", "Category", "Type", "Plate"],
		mustCols: ["Vehicle", "Total"],
		reportColsOrder: ["Vehicle", "Plate", "Category", "Type", ...months],
		formatter: yearlyEngineHoursFormatter,
	},
	{
		value: "mileage",
		label: "Mileage",
		sheet: "Main",
		cols: ["Vehicle", "Total", "Category", "Type", "Plate"],
		groupingCols: ["Vehicle", "Category", "Type", "Plate"],
		mustCols: ["Vehicle", "Total"],
		reportColsOrder: ["Vehicle", "Plate", "Category", "Type", ...months],
		formatter: yearlyMileageFormatter,
	},
	{
		value: "ruunigafterdutyoutofgeofence",
		label: "Running After Duty",
		sheet: "Details",
		cols: ["Vehicle", "Total", "Category", "Type", "Plate"],
		groupingCols: ["Vehicle", "Category", "Type", "Plate"],
		mustCols: ["Vehicle", "Total"],
		reportColsOrder: ["Vehicle", "Plate", "Category", "Type", ...months],
		formatter: yearlyRuuningAfterDutyOutOfGeoFenceFormatter,
	},
	{
		value: "parkedondutyoutofgeofence",
		label: "Parked On Duty Out of Geofence",
		sheet: "Details",
		cols: ["Vehicle", "Total", "Category", "Type", "Plate"],
		groupingCols: ["Vehicle", "Category", "Type", "Plate"],
		mustCols: ["Vehicle", "Total"],
		reportColsOrder: ["Vehicle", "Plate", "Category", "Type", ...months],
		formatter: yearlyParkedOnDutyOutOfGeoFenceFormatter,
	},
	{
		value: "idleunits",
		label: "Idle Units",
		sheet: "Details",
		cols: ["Vehicle", "Cost", "Idle hours", "Category"],
		groupingCols: ["Vehicle", "Category"],
		mustCols: ["Vehicle", "Cost", "Idle hours"],
		reportColsOrder: [
			"Vehicle",
			"Category",
			// "Cost",
			// "Idle hours",
			...months.map((m) => `${m} Idle hours`),
			...Array(4)
				.fill("")
				.map((_, i) => `Week ${i + 1} Idle hours`),
			...months.map((m) => `${m} Cost`),
			...Array(4)
				.fill("")
				.map((_, i) => `Week ${i + 1} Cost`),
		],
		formatter: yearlyIdleUnitsFormatter,
	},
];
